import { Footer } from '@/features/footer';
import { Header } from '@/features/header';
import { Outlet } from 'react-router-dom';
import ScrollToTop from '@/widgets/scroll-to-top/scroll-to-top.tsx';

export const MainLayout = () => {
  return (
    <>
      {/* <Header /> */}
      <Outlet />
      {/* <Footer /> */}
      <ScrollToTop />
    </>
  );
};
